import { useState, useContext, useEffect, useCallback, useRef } from "react";
import { DAppContext } from "./context";
import { Toaster } from "react-hot-toast";
import { ReactComponent as Opensea } from "./opensea.svg";
import { ReactComponent as Twitter } from "./twitter.svg";
import Mining from "./doin.mp3";

function App() {
  const audioRef = useRef(null);
  const [count, setCount] = useState(2);
  const [mintedCount, setMintedCount] = useState(0);

  const {
    connectBrowserWallet,
    mint,
    userData,
    transactionHash,
    contractDetails,
  } = useContext(DAppContext);

  const handleCounter = (e) => {
    const { dataset } = e.currentTarget;
    const { pos } = dataset;

    if (pos === "plus" && count < 10) {
      setCount(() => count + 1);
      return;
    }

    if (count - 1 !== 0) {
      setCount(() => count - 1);
    }
  };

  const handleCustomMint = useCallback(() => {
    mint(count);
  }, [count, userData]);

  const connectWallet = () => {
    audioRef.current.play();
    connectBrowserWallet();
  };

  useEffect(() => {
    if (!!contractDetails) {
      setMintedCount(parseInt(Number(contractDetails.alreadyMinted)));
    }
  }, [contractDetails]);
  console.log(userData);
  return (
    <>
      <audio ref={audioRef} playsInline autoPlay loop>
        <source src={Mining} type="audio/mpeg" />
      </audio>
      <div className="bull-container">
        {!!userData && (
          <div className="bull-form">
            <h2>1 FREE Mint for every bull</h2>
            {/* {!!contractDetails && (
             <p className="counter">
               {mintedCount} / 3333
             </p>
           )} */}
            <div className="bull-form-counter">
              <button
                onClick={handleCounter}
                type="button"
                className="bull-btn"
                data-pos="minus"
              >
                -
              </button>
              <span> {count} </span>
              <button
                onClick={handleCounter}
                type="button"
                className="bull-btn"
                data-pos="plus"
              >
                +
              </button>
              <button
                onClick={handleCustomMint}
                type="button"
                className="claim-btn"
              >
                Mint
              </button>
            </div>
            <div className="bull-claimers">
              <button
                onClick={() => mint(5)}
                type="button"
                className="claim-btn"
              >
                Mint 5
              </button>
              <button
                onClick={() => mint(10)}
                type="button"
                className="claim-btn"
              >
                Mint 10
              </button>
            </div>
            {transactionHash && (
              <a
                href={`https://etherscan.io/tx/${transactionHash}`}
                target="_blank"
                rel="noreferrer"
                className="claim-btn transaction-hash"
              >
                See transaction
              </a>
            )}
            <p>here #fortheculture 8.888 unique bullz for 0.0066</p>
          </div>
        )}

        <div className="bull-wrapper">
          {userData?.account?.length ? (
            <span className="conencted connect-wallet">
              <span>{userData?.account}</span>
            </span>
          ) : (
            <button
              type="button"
              className="connect-wallet"
              onClick={connectWallet}
              disabled={!!userData?.account?.length}
            >
              Connect Wallet
            </button>
          )}
          <a
              href="https://opensea.io/collection/loser-bulls"
              target="_blank"
              rel="noreferrer"
              className="bull-link-item"
            >
              <Opensea />
            </a> 
          <a
            href="https://twitter.com/loserbulls_wtf"
            target="_blank"
            rel="noreferrer"
            className="bull-link-item bull-twitter"
          >
            <Twitter />
          </a>
        </div>
        <h1>Meet the bulls NFTs under cc0 that got rekt during this timeline</h1>
      </div>
      <Toaster position="top-center" reverseOrder={false} />
    </>
  );
}

export default App;
